// Trick VH for mobile Devices
let vh = window.innerHeight * 0.01;

let setVHSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', setVHSize);
window.addEventListener('resize', setVHSize);

// Navtoggler Funcionality

document.querySelector('.header__navtoggler').addEventListener('click', () => {
  document.body.classList.toggle('activenav');
})

// Offcanvas Functionality
for (let parentMenuItem of document.querySelectorAll('.header__navigation-mainnav li.parent')) {
  parentMenuItem.querySelector('a, span').addEventListener('click', (e) => {
    if (window.innerWidth < 1200) {
      e.preventDefault();
      parentMenuItem.classList.toggle('clicked');
    }
  })
}

// Submenu Positioning on Large Devices

let setSubmenuOffset = () => {
  for (let firstLevelItem of document.querySelectorAll('.header__navigation-mainnav > ul > li')) {
    if(firstLevelItem.querySelector('ul')) {
      if (window.innerWidth >= 1024) {
        firstLevelItem.querySelector('ul').style.paddingLeft = firstLevelItem.getBoundingClientRect().left + 'px';
      } else {
        firstLevelItem.querySelector('ul').style.paddingLeft = 'inherit';
      }
    }
  }
}

// AOS - Initialize AOS Library

document.addEventListener('DOMContentLoaded', () => {
  setSubmenuOffset();
})
window.addEventListener('resize', () => {
  setSubmenuOffset()
})
let initializeAOS = () => {
  AOS.init({
    duration: 500,
    easing: 'ease-in-out-quart',
    once: true,
    anchorPlacement: 'top-bottom'
  });
}
window.setTimeout(initializeAOS,5);

// Logic for Product-Tabs

for (let tabNav of document.querySelectorAll('ul.tabs')) {
  for (let tabNavItem of tabNav.querySelectorAll('.tabs__item')) {
    tabNavItem.addEventListener('click', () => {
      for (let tabNavItem of tabNav.querySelectorAll('.tabs__item')) {
        tabNavItem.classList.remove('active');
      }
      for (let productTab of document.querySelectorAll('.productpage__content-tab')) {
        if (productTab.id == tabNavItem.dataset.tab) {
          productTab.classList.add('active');
        } else {
          productTab.classList.remove('active');
        }
      }
      tabNavItem.classList.add('active');
    })

  }
}
